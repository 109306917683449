._circle {
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    width: 300px;
    padding: 30px 0;
    margin: 0 auto;
    border-radius: 15px;
    font-size: 24px;
    color: white;
    margin: auto 30px;
}

.footer {
    background-color: black;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: space-evenly;
}

.footer * {
    color: darkgreen;
    font-size: 36px;
    font-family: monospace;
}
