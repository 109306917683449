* {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
}

header {
    width: 100%;
    height: 80px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.__container {
    width: 90vw !important;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
}

.nav-header {
    display: flex;
    position: relative;
    float: left;
    justify-content: space-around;
    padding-right: 50px;
    margin: 5px 100px;
}

.nav-link {
    text-decoration: none;
    color: #000;
}

.selected-nav a {
    /* position: absolute;
  bottom: -10px;
  margin: auto;
  width: 10px;
  height: 4px;
  border-radius: 50px;
  background-color: #1890ff;
  content: ""; */
    color: #1890ff;
}

.header-list {
    padding-top: 10px;
}

.list-item {
    display: flex;
    justify-content: center;
    float: right;
    padding: 10px 30px;
}

.list-item input {
    border: none;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    padding: none;
    text-align: center;
    width: 80px;
}

.input-num {
    text-align: right;
    width: 160px;
}

.main-num span {
    font-size: 72px;
}

.secondry-num span {
    font-size: 36px;
}

.table-btn {
    float: right;
    margin: 20px;
}

.btn-add {
    position: absolute;
    bottom: 30px;
    right: 30px;
    scale: 1.5;
}

.box {
    width: 50%;
    box-sizing: border-box;
    padding: 80px;
    margin: 50px;
    background: #1890ff;
}

.avatarIcon {
    font-weight: bold;
    background-color: #1890ff;
}

.hover-box-shadow:hover {
    box-shadow: 6px 6px 36px 8px rgba(0, 144, 255, 0.2);
}

/* Mobile styles */
@media (max-width: 768px) {
    .center {
        padding: 10px !important;
    }
    header {
        height: 300px;
        display: block;
    }

    .nav-header {
        margin: 5px;
        display: block;
        position: absolute;
    }

    .container {
        display: block;
    }

    .container .hover-box-shadow {
        margin: 30px;
    }

    .ant-table-row-expand-icon-cell {
        display: none;
    }

    table {
        table-layout: initial !important;
        width: 500px !important;
    }

    .ant-table {
        font-size: 12px !important;
    }

    .ant-table-thead th {
        white-space: bold !important;
        word-wrap: break-word; /* Break words if needed */
        max-width: none !important;
    }
    .ant-table-tbody th {
        white-space: bold !important;
        word-wrap: break-word; /* Break words if needed */
        max-width: none !important;
    }
}

a {
    transition: 0.2s;
}

a:hover {
    color: rgb(0, 146, 255);
    font-size: 20px;
    text-decoration: underline;
}
