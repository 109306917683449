.seller {
    background-color: rgb(203, 203, 203);
    margin: 10px;
    padding: 20px;
    color: white;
}

a {
    text-decoration: none;
    color: white;
}
