* {
    padding: 0;
    margin: 0;
}

.center {
    text-align: center;
}

.flex {
    display: flex;
}

ul li {
    list-style: none;
}

header {
    width: 100%;
    height: 80px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.nav-header {
    display: flex;
    position: relative;
    float: left;
    justify-content: space-around;
    padding-right: 50px;
    margin: 5px 100px;
}

.nav-header li {
    padding: 20px 5px;
}

.nav-link {
    text-decoration: none;
    color: #000;
}

.selected-nav button {
    /* position: absolute;
  bottom: -10px;
  margin: auto;
  width: 10px;
  height: 4px;
  border-radius: 50px;
  background-color: #1890ff;
  content: ""; */
    background-color: #1890ff;
    color: #fff;
}
.selected-nav button:hover {
    color: #fff !important;
}

.header-list {
    padding-top: 10px;
}

.list-item {
    display: flex;
    justify-content: center;
    float: right;
    padding: 10px 30px;
}

.list-item input {
    border: none;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    padding: none;
    text-align: center;
    width: 80px;
}

.input-num {
    text-align: right;
    width: 160px;
}

.main-num span {
    font-size: 72px;
}

.secondry-num span {
    font-size: 36px;
}

.table-btn {
    float: right;
    margin: 20px;
}

.btn-add {
    position: absolute;
    bottom: 30px;
    right: 30px;
    scale: 1.5;
}

.box {
    width: 50%;
    box-sizing: border-box;
    padding: 80px;
    margin: 50px;
    background: #1890ff;
}

@media (max-width: 768px) {
}
